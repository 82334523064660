<template>
  <div class="container">
    <div class="container content">
      <!-- <v-alert
        text
        class="gepi-alert"
        color="cyan darken-2"
        icon="mdi-alert-circle"
      >
        The data in GEPI.io is currently incomplete. We will integrate
        additional data sources and build new GEPI.io features as the Centers
        continue to reimagine internal data standards and processes.
      </v-alert> -->
      <div class="search-type">
        <div class="award-icon-box">
          <div class="award-icon"></div>
        </div>
        <h2>Grants</h2>
      </div>
      <div class="search-options">
        <div class="box-container">
          <div class="box-half grant-search">
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                outlined
                class="ml-n6"
              ></v-text-field>
            </v-card-title>
          </div>
          <div class="box-quarter">
            <v-select
              class="pt-4 pl-16"
              v-model="type"
              :items="types"
              label="Type"
              outlined
              hide-details
            ></v-select>
          </div>
          <div class="box-quarter">
            <v-select
              class="pt-4 pl-16"
              v-model="center"
              :items="centers"
              label="Center"
              outlined
              hide-details
            ></v-select>
            <v-switch
              class="pl-16 m-0"
              label="Include completed grants"
              inset
              v-model="includeCompletedGrants"
              @change="getAwards()"
            ></v-switch>
          </div>
        </div>
      </div>
      <div class="results-count mt-n8">
        Showing <strong>{{ count }}</strong> Grant<span v-if="count != 1"
          >s</span
        >
      </div>
      <v-card>
        <v-data-table
          :headers="headers"
          :items="awards"
          :search="search"
          multi-sort
          :loading="loading"
          loading-text="Loading... Please wait"
          @pagination="getPageCount"
        >
          <template v-slot:item.name="{ item }">
            <a :href="item.id">{{ item.name }}</a>
          </template>
          <template v-slot:item.center="{ item }">
            <v-chip
              v-if="item.center == 'GovEx'"
              color="var(--govex)"
              class="white--text font-weight-medium govex-chip"
              >{{ item.center }}</v-chip
            >
            <v-chip
              v-else-if="item.center == 'BCPI'"
              color="var(--bcpi)"
              class="white--text font-weight-medium bcpi-chip"
              >{{ item.center }}</v-chip
            >
            <v-chip
              v-else-if="item.center == 'Partner'"
              class="white--text font-weight-medium"
              >{{ item.center }}</v-chip
            >
            <span v-else>-</span>
          </template>
          <template v-slot:item.type[1]="{ item }">
            <span v-if="item.type">{{ item.type[1] }}</span>
            <span v-else>-</span>
          </template>
          <template v-slot:item.start_date="{ item }">
            <span>{{ item.start_date | formatDate | formatNull }}</span>
          </template>
          <template v-slot:item.planned_end_date="{ item }">
            <span>{{ item.planned_end_date | formatDate | formatNull }}</span>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>
<style>
/* Page CSS */

.award-icon {
  height: 55px;
  width: 52px;
  margin: 0px auto;
  padding: 10px;
  background-color: white;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-image: url("../assets/icons/awards.svg");
}

.award-icon-box {
  margin: 15px 10px;
  border-radius: 10px;
  height: 70px;
  width: 70px;
  padding-top: 10px;
  max-height: 140px;
  max-width: 140px;
  background-color: var(--aqua);
}

.input-box {
  width: 85%;
}
label[for="search"] {
  height: 25px;
}
#search {
  position: relative;
  top: -14px;
}
.page-search-button {
  height: 37px;
  margin-top: 4px;
  border-color: #dfdfdf;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  margin-right: 10px;
  background-color: rgb(248, 248, 248);
}
.page-search-icon {
  height: 25px;
  width: 25px;
  padding-top: 10px;
  margin: auto;
  background-color: #a1a1a1;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-image: url("../assets/icons/search.svg");
}
.govex-center {
  color: var(--govex);
}
.bcpi-center {
  color: var(--bcpi);
}

/* Responsive formatting */
@media screen and (max-width: 1000px) {
  .grant-search {
    padding-left: 10px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    padding: 0 3px;
  }
  .table-label.project-status-bar,
  .table-item.project-status-bar {
    min-width: 100px !important;
  }
  .status-bar {
    width: 100px !important;
    margin-left: 10px !important;
  }
  .search-options {
    padding-bottom: 50px;
  }
}
</style>
<script>
// import Status from "../components/Status";
import pageCountMixin from "../mixins/pageCount";
export default {
  mixins: [pageCountMixin],
  components: {},
  title() {
    return `Grants - GEPI.io`;
  },
  data() {
    return {
      awards: [],
      centers: ["", "GovEx", "BCPI"],
      types: ["", "Grant", "Account"],
      search: "",
      center: "",
      type: "",
      includeCompletedGrants: false,
      loading: true,
    };
  },
  created() {
    this.getAwards();
  },
  computed: {
    headers() {
      return [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
          cellClass: "table-item table-link",
        },
        {
          text: "Center",
          value: "center",
          sortable: true,
          filter: (value) => {
            if (!this.center) return true;
            return value === this.center;
          },
          cellClass: "table-item",
        },
        {
          text: "Type",
          value: "type[1]",
          sortable: true,
          filter: (value) => {
            if (!this.type) return true;
            return value === this.type;
          },
          cellClass: "table-item",
        },
        {
          text: "Start Date",
          value: "start_date",
          sortable: true,
          cellClass: "table-item",
        },
        {
          text: "End Date",
          value: "planned_end_date",
          sortable: true,
          cellClass: "table-item",
        },
        {
          text: "Projects",
          value: "project_count",
          sortable: true,
          cellClass: "table-item",
        },
        {
          text: "Program Cohorts",
          value: "program_count",
          sortable: true,
          cellClass: "table-item",
        },
      ];
    },
  },
  methods: {
    getAwards: async function() {
      this.awards = [];
      this.loading = true;
      let url = `awards?ordering=name&completed=${this.includeCompletedGrants}`;
      while (url) {
        let response = await this.$api.get(url);
        await this.awards.push.apply(this.awards, response.data.results);
        url = response.data.next;
      }
      this.loading = false;
    },
    getStatuses: function(award) {
      let statuses = [];
      for (var program of award.programs) {
        program.status.length && statuses.push(program.status);
      }
      return statuses;
    },
  },
};
</script>
